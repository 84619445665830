.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.controls {
    background: rgba(255, 255, 255, 0.75);
}

.color-picker-container {
    overflow-x: auto;
    white-space: nowrap;
    padding: 4px;
    background: rgba(255, 255, 255, 0.75); /* semi-transparent white */
}

.color-picker {
    display: inline-flex;
    padding-bottom: 8px;
}

.color-button {
    width: 30px;
    height: 30px;
    border: 2px solid transparent; /* Adjusted for border on selected color */
    border-radius: 50%;
    margin: 4px 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    transition: all 0.3s ease;
}

.color-button:hover {
    transform: scale(1.1);
}

.selected-color {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

